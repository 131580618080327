/* 
 *  Core Owl Carousel CSS File
 */
.owl-carousel {
    display: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    /* position relative and z-index fix webkit rendering fonts issue */
    position: relative;
    z-index: 1;
}
.owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
}
.owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}
.owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px);
}
.owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
    display: block;
    width: 100%;
    -webkit-transform-style: preserve-3d;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
    display: none;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.owl-carousel.owl-loaded {
    display: block;
}
.owl-carousel.owl-loading {
    opacity: 0;
    display: block;
}
.owl-carousel.owl-hidden {
    opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
    display: none;
}
.owl-carousel.owl-drag .owl-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.owl-carousel.owl-grab {
    cursor: move;
    cursor: -webkit-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab;
}
.owl-carousel.owl-rtl {
    direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
    float: right;
}

/* No Js */
.no-js .owl-carousel {
    display: block;
}

/* CUSTOM CSS */
.carousel-container{
    overflow: hidden;
}
.owl-carousel{
    cursor: pointer;
    min-height: 80px;
}

.owl-carousel-navigation .owl-controls .owl-nav{
    position: absolute;
    top: 50%;
    height: 60px;
    right: 0;
    margin-top: -20px;
}

.owl-carousel-navigation .owl-controls .owl-nav .owl-prev,
.owl-carousel-navigation .owl-controls .owl-nav .owl-next{
    position: absolute;
    right: -30px;
    width: 30px;
    height: 30px;
    background-color: #353e44;
    text-indent: -9999px;
    background-repeat: no-repeat;
    background-position: center;

    transition: all 0.2s ease-in-out 0s;
    -webkit-transition: all 0.2s ease-in-out 0s;
    -moz-transition: all 0.2s ease-in-out 0s;
    -ms-transition: all 0.2s ease-in-out 0s;
}

.owl-carousel-navigation .owl-controls .owl-nav .owl-prev{ 
    top: -15px;  
    background-image: url('../img/nivo-prev-nav.png');

}

.owl-carousel-navigation.owl-carousel:hover .owl-controls .owl-nav .owl-prev,
.owl-carousel-navigation.owl-carousel:hover .owl-controls .owl-nav .owl-next{
    right: 0;
}

.owl-carousel-navigation .owl-controls .owl-nav .owl-next{
    top: 15px;
    background-image: url('../img/nivo-next-nav.png');
}

.owl-theme .owl-dots{
    text-align: center;
    display: table;
    margin: 20px auto 0;
}

.owl-controls .owl-dot{
    float: left;
}

.owl-controls span{
    display: block;
    width: 12px;
    height: 12px;
    margin: 5px 7px;
    
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    background-color: #ddd;
}

.owl-buttons .owl-nav{
    text-align: center;
}

.owl-buttons .owl-nav{
    margin-top: 30px;
}

.owl-buttons .owl-prev,
.owl-buttons .owl-next{
    display: inline-block;
    margin: 0 5px;
    padding: 10px 25px;
    background-color: #fcfcfc;
    border: 1px solid #ddd;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    
    transition: all 0.3s ease-in-out 0s;
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    -ms-transition: all 0.3s ease-in-out 0s;
}

.owl-buttons .owl-prev:hover,
.owl-buttons .owl-next:hover{
    color: #fff;
}