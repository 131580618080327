.owl-carousel {
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  z-index: 1;
  display: none;
  position: relative;
}

.owl-carousel .owl-stage {
  -ms-touch-action: pan-Y;
  position: relative;
}

.owl-carousel .owl-stage:after {
  content: ".";
  clear: both;
  visibility: hidden;
  height: 0;
  line-height: 0;
  display: block;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  position: relative;
}

.owl-carousel .owl-item img {
  width: 100%;
  -webkit-transform-style: preserve-3d;
  display: block;
}

.owl-carousel .owl-nav.disabled, .owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-nav .owl-prev, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-dot {
  cursor: pointer;
  cursor: hand;
  -webkit-user-select: none;
  user-select: none;
  -khtml-user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  display: none;
}

.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.no-js .owl-carousel {
  display: block;
}

.carousel-container {
  overflow: hidden;
}

.owl-carousel {
  cursor: pointer;
  min-height: 80px;
}

.owl-carousel-navigation .owl-controls .owl-nav {
  height: 60px;
  margin-top: -20px;
  position: absolute;
  top: 50%;
  right: 0;
}

.owl-carousel-navigation .owl-controls .owl-nav .owl-prev, .owl-carousel-navigation .owl-controls .owl-nav .owl-next {
  width: 30px;
  height: 30px;
  text-indent: -9999px;
  background-color: #353e44;
  background-position: center;
  background-repeat: no-repeat;
  transition: all .2s ease-in-out;
  position: absolute;
  right: -30px;
}

.owl-carousel-navigation .owl-controls .owl-nav .owl-prev {
  background-image: url("nivo-prev-nav.2922aa1c.png");
  top: -15px;
}

.owl-carousel-navigation.owl-carousel:hover .owl-controls .owl-nav .owl-prev, .owl-carousel-navigation.owl-carousel:hover .owl-controls .owl-nav .owl-next {
  right: 0;
}

.owl-carousel-navigation .owl-controls .owl-nav .owl-next {
  background-image: url("nivo-next-nav.30ddbc02.png");
  top: 15px;
}

.owl-theme .owl-dots {
  text-align: center;
  margin: 20px auto 0;
  display: table;
}

.owl-controls .owl-dot {
  float: left;
}

.owl-controls span {
  width: 12px;
  height: 12px;
  background-color: #ddd;
  border-radius: 20px;
  margin: 5px 7px;
  display: block;
}

.owl-buttons .owl-nav {
  text-align: center;
  margin-top: 30px;
}

.owl-buttons .owl-prev, .owl-buttons .owl-next {
  text-transform: uppercase;
  background-color: #fcfcfc;
  border: 1px solid #ddd;
  margin: 0 5px;
  padding: 10px 25px;
  font-size: 12px;
  font-weight: 700;
  transition: all .3s ease-in-out;
  display: inline-block;
}

.owl-buttons .owl-prev:hover, .owl-buttons .owl-next:hover {
  color: #fff;
}

/*# sourceMappingURL=index.dd8e7d3c.css.map */
